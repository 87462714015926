
















































import { Component, Vue, Watch } from 'vue-property-decorator'
import sidebarModule from '@/store/sidebar'
import category from '@/store/category'
import leftovers from '@/store/leftovers'
import { RouteNames } from '@/router'
import { SubcategoryFragment } from '@/graphql/default/category.graphql'
import { cart } from '@/store/cart/cartModule'
import terminalModule from '@/store/terminal'
const CheckboxButton = () => import('@/components/CheckboxButton.vue')
const Switcher = () => import('@/components/Switcher.vue')
const MiniBasket = () => import('@/views/miniBasket.vue')
const CatalogOfNewProducts = () => import('@/views/catalogOfNewProducts.vue')

@Component({
  components: { CatalogOfNewProducts, MiniBasket, Switcher, CheckboxButton }
})
export default class sidebar extends Vue {
  selectedSub:Record<SubcategoryFragment['id'], boolean>={}

  get isOpenSidebar(): boolean {
    return sidebarModule.state.open
  }

  closeSidebar():void{
    sidebarModule.mutations.setOpen(false)
  }

  get isShowBasket() {
    return !!cart.products.length &&
      this.$route.name !== RouteNames.basket &&
      !terminalModule.getters.terminal?.isDemoMode
  }

  get isShowNewItems() {
    return !!leftovers.threeNewLeftovers?.length
  }

  get productSubcategory() {
    return category.getters.productSubcategory
  }

  @Watch('productSubcategory', { immediate: true })
  watchProductSubcategory(productSubcategory:
                            typeof category.getters.productSubcategory) {
    productSubcategory?.forEach((product) => {
      this.$set(this.selectedSub, product.id, !!this.selectedSub[product.id])
    })
  }

  get queryForStore() {
    return {
      subcategory: Object.entries(this.selectedSub)
        .reduce((acc:string[], [key, val]) => {
          if (val) { acc.push(key) }
          return acc
        }, [])
    }
  }

  get newItems() {
    return leftovers.newLeftovers
  }

  @Watch('queryForStore', { deep: true })
  watchQueryForStore() {
    this.$router.push({ name: RouteNames.store, query: this.queryForStore })
      .catch((e) => {
        if (e.name !== 'NavigationDuplicated') {
          throw e
        }
      })
  }

  @Watch('$route.query.subcategory')
  watchQuerySubcategory(subcategory:
                          SubcategoryFragment['id'][]
                          | SubcategoryFragment['id']) {
    Object.entries(this.selectedSub).forEach(([id]) => {
      this.selectedSub[id] = !!subcategory?.includes(id)
    })
  }
}
